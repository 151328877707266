@font-face {
  font-family: 'icomoon';
  src: url('~/assets/fonts/icons/icomoon.eot?llcfxy');
  src: url('~/assets/fonts/icons/icomoon.eot?llcfxy#iefix') format('embedded-opentype'),
  url('~/assets/fonts/icons/icomoon.ttf?llcfxy') format('truetype'),
  url('~/assets/fonts/icons/icomoon.woff?llcfxy') format('woff'),
  url('~/assets/fonts/icons/icomoon.svg?llcfxy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-invoice:before {
  content: "\e938";
  color: #b79a80;
}

.icon-check:before {
  content: "\e913";
}

.icon-double-check:before {
  content: "\e92d";
}

.icon-engineering:before {
  content: "\e909";
  color: #585554;
}

.icon-arrow-left:before {
  content: "\e901";
}

.icon-arrow-right:before {
  content: "\e905";
}

.icon-at:before {
  content: "\e906";
}

.icon-build:before {
  content: "\e907";
}

.icon-building:before {
  content: "\e93b";
}

.icon-calendar:before {
  content: "\e908";
}

.icon-camera:before {
  content: "\e915";
}

.icon-check1:before {
  content: "\e91f";
}

.icon-checkbox:before {
  content: "\e90a";
}

.icon-chevron-bottom:before {
  content: "\e920";
}

.icon-chevron-left:before {
  content: "\e921";
}

.icon-chevron-right:before {
  content: "\e922";
}

.icon-create-account:before {
  content: "\e90c";
}

.icon-delete:before {
  content: "\e90d";
}

.icon-double-arrow-left:before {
  content: "\e900";
}

.icon-double-arrow-right:before {
  content: "\e904";
}

.icon-earth:before {
  content: "\e90e";
}

.icon-edit:before {
  content: "\e90f";
}

.icon-edit-note:before {
  content: "\e910";
}

.icon-engineering-office:before {
  content: "\e911";
}

.icon-eye:before {
  content: "\e90b";
}

.icon-file-plus:before {
  content: "\e912";
}

.icon-filters:before {
  content: "\e916";
}

.icon-globe:before {
  content: "\e914";
}

.icon-hash:before {
  content: "\e917";
}

.icon-id:before {
  content: "\e93c";
}

.icon-id-card:before {
  content: "\e919";
}

.icon-id-card-long:before {
  content: "\e918";
}

.icon-instagram:before {
  content: "\e91a";
}

.icon-judgment:before {
  content: "\e936";
}

.icon-linkedin:before {
  content: "\e91b";
}

.icon-location:before {
  content: "\e91c";
}

.icon-login:before {
  content: "\e91d";
}

.icon-long-arrow-left:before {
  content: "\e902";
}

.icon-long-arrow-right:before {
  content: "\e903";
}

.icon-mail:before {
  content: "\e91e";
}

.icon-note:before {
  content: "\e928";
}

.icon-pdf:before {
  content: "\e937";
}

.icon-phone:before {
  content: "\e923";
}

.icon-plus:before {
  content: "\e933";
}

.icon-power:before {
  content: "\e924";
}

.icon-process:before {
  content: "\e925";
}

.icon-region:before {
  content: "\e926";
}

.icon-request:before {
  content: "\e927";
}

.icon-save:before {
  content: "\e929";
}

.icon-search:before {
  content: "\e92a";
}

.icon-settings:before {
  content: "\e92b";
}

.icon-size:before {
  content: "\e92c";
}

.icon-target:before {
  content: "\e92e";
}

.icon-twitter:before {
  content: "\e92f";
}

.icon-unlock:before {
  content: "\e939";
}

.icon-user:before {
  content: "\e930";
}

.icon-users:before {
  content: "\e932";
}

.icon-users-plus:before {
  content: "\e931";
}

.icon-verify:before {
  content: "\e934";
}

.icon-youtube:before {
  content: "\e935";
}

.icon-zip:before {
  content: "\e93a";
}
