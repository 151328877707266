@import "utils";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: $mongoose;
}

.mat-select-placeholder.mat-select-min-line,
.mat-option-text,
.mat-select-min-line {
  font-family: $font-family-base;
  color: $cloudy;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
}

.btn.btn-align-input {
  padding: 0.8rem 1.5rem;
}

.modal {
  &-dialog {
    margin-right: 25vw;
    transform: translateX(-100%);
    transition: transform 0.25s;
  }

  &-content {
    height: 100vh;
    overflow: auto;
  }

  &-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: $modal-content-bg;
    height: 100px;
    align-items: center;

    .close {
      margin: 0 0 0 auto !important;
      font-size: 18px;

      span {
        display: none;
      }

      &::before {
        font-family: "icomoon";
        content: "\e905";
        font-size: 14px;
        margin-left: 8px;
      }

      &::after {
        content: "العودة";
        text-decoration: underline;
      }
    }
  }

  &-footer {
    justify-content: flex-start;
  }
}

.admin-modal {
  .modal-body {
    flex: 0;
  }
}

// .modal-dialog-scrollable .modal-body {
//   overflow-y: hidden;
// }

.modal-body .row .col-md-8.overflow-auto {
  padding: 0 2.5rem;
}

.modal-stopped.modal {
  .modal-content {
    width: 500px;
    margin: 0 auto;
    text-align: center;
    height: auto;
  }

  .modal-body {
    padding-top: 0;
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .modal-dialog {
    margin-left: 25vw;
    transform: translate(0, 2vw);
  }

  .modal-header {
    padding: 0rem;
    align-items: center;
  }

  .close {
    margin: 0 auto 0 0 !important;
    font-size: 50px;
    font-weight: 400;

    span {
      display: block;
    }

    &::before,
    &::after {
      all: unset;
    }
  }
}

.lands-modal.modal {
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .modal-dialog {
    margin-left: 25vw;
    transform: translate(0, 2vw);
  }

  .modal-content {
    height: auto;
  }

  .modal-header {
    padding: 1rem 4rem 1rem 2rem;
    align-items: center;
  }

  .close {
    margin: 0 auto 0 0 !important;
    font-size: 50px;
    font-weight: 400;

    span {
      display: block;
    }

    &::before,
    &::after {
      all: unset;
    }
  }
}

.default-modal {
  .modal-dialog-centered.modal-dialog-scrollable {
    height: auto;
  }

  &-backdrop {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
  .modal {
    &-dialog {
      max-width: 60vw;
      margin: 0 auto;
    }
    &-content {
      height: 100%;
    }
    &-title {
      margin-right: 1.5rem;
    }
    &-header {
      .close {
        margin: 0 0 0 0 !important;

        span {
          display: inline-block;
          font-size: 40px;
        }

        &::before,
        &::after {
          all: unset;
        }
      }
    }
    &-footer {
      justify-content: center;
    }
  }
}
