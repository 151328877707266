@import "./variables";
@import "icon-font";

@font-face {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 200;
  src: local('DIN Next LT W23 Ultra Light'), url('~/assets/fonts/din-next-lt-w23-ultra-light.woff') format('woff');
  font-display: swap; /* Ensures fallback text is shown while the font loads */
}

@font-face {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 300;
  src: local('DIN Next LT W23 Light'), url('~/assets/fonts/din-next-lt-w23-light.woff') format('woff');
  font-display: swap; /* Ensures fallback text is shown while the font loads */
}

@font-face {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  src: local('DIN Next LT W23 Regural'), url('~/assets/fonts/din-next-lt-w23-regular.woff') format('woff');
  font-display: swap; /* Ensures fallback text is shown while the font loads */
}

@font-face {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 500;
  src: local('DIN Next LT W23 Medium'), url('~/assets/fonts/din-next-lt-w23-medium.woff') format('woff');
  font-display: swap; /* Ensures fallback text is shown while the font loads */
}

@font-face {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 700;
  src: local('DIN Next LT W23 Bold'), url('~/assets/fonts/din-next-lt-w23-bold.woff') format('woff');
  font-display: swap; /* Ensures fallback text is shown while the font loads */
}

@font-face {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 800;
  src: local('DIN Next LT W23 Heavy'), url('~/assets/fonts/din-next-lt-w23-heavy.woff') format('woff');
  font-display: swap; /* Ensures fallback text is shown while the font loads */
}

@font-face {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 900;
  src: local('DIN Next LT W23 Black'), url('~/assets/fonts/din-next-lt-w23-black.woff') format('woff');
  font-display: swap; /* Ensures fallback text is shown while the font loads */
}
