@import "scss/utils";
@import "scss/utils/fonts";
@import "@ng-select/ng-select/scss/default.theme";

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.donut {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;

  &.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.app {
  &-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &-content {
    padding: 2rem;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  &-title {
    font-size: 24px;
    font-weight: $font-weight-bold;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-sub-title {
    font-size: 18px;
    font-weight: $font-weight-normal;
  }

  &-section {
    padding: 20px 0;

    &-title {
      font-size: 18px;
      font-weight: $font-weight-bold;
      color: $tertiary;
    }
  }
}

.header {
  height: $header-height;
  border-bottom: 1px solid $border-color;
  background-color: $header-bg;

  &__logo {
    height: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid $border-color;
  }
}

.table {
  &-wrapper {
    overflow-x: auto;
    margin-bottom: 1rem;

    .table {
      margin-bottom: 0;
    }
  }

  thead {
    th {
      border: none;
      font-size: 12px;
    }
  }

  tbody {
    font-weight: $font-weight-bold;
    font-size: 14px;

    tr {
      border: $table-border-width solid $table-border-color;
      background-color: white;
    }
  }

  th, td {
    vertical-align: middle;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn {
    padding: .5rem;
  }
}

.switch {
  position: relative;

  &-input {
    position: absolute;
    opacity: 0;

    &:checked {
      + .switch-label {
        &::before {
          background-color: $primary;
          left: calc(100% - 13px)
        }
      }
    }
  }

  &-label {
    height: 15px;
    width: 30px;
    background-color: $switch-bg;
    margin: 0;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      width: 11px;
      height: 11px;
      background-color: $switch-indicator-bg;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: left .25s;
    }
  }
}

.btn {
  min-width: 145px;

  &.btn-auto-width {
    min-width: auto;
  }

  &-tertiary {
    color: white;
  }
}

.dropdown {
  &-toggle {
    &.no-arrow {
      &::after {
        display: none;
      }
    }

    &.custom-arrow {
      &::after {
        width: 10px;
        height: 10px;
        border-width: 0 1px 1px 0;
        transform: translateX(-8px) rotate(45deg);
        border-color: $cloudy;
        border-style: solid;
      }
    }
  }
}

.rtl,
[dir="rtl"] {
  .dropdown-menu {
    left: inherit !important;
  }

  button.toast-close-button {
    right: inherit !important;
    left: 0;
  }
}

.pagination {
  &-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .page-item {
    &:first-child, &:last-child {
      .page-link {
        background: none;
        border: none;
      }
    }

    .page-link {
      &[aria-label="Previous"],
      &[aria-label="Next"] {
        &::after, &::before {
          text-decoration: underline;
          font-size: 12px;
          font-weight: $font-weight-bold;
        }

        &:not([aria-disabled="true"]) {
          color: $pagination-active-color;
        }
      }

      &[aria-label="Previous"] {
        &::after {
          content: 'السابق';
        }
      }

      &[aria-label="Next"] {
        &::before {
          content: 'التالي';
        }
      }
    }

    &.active {
      position: relative;

      &::before {
        content: '';
        height: 2px;
        position: absolute;
        bottom: 0;
        right: 5px;
        left: 5px;
        background-color: $pagination-active-color;
        z-index: 10;
      }
    }
  }
}

label {
  font-weight: $font-weight-bold;

  &.required {
    &::after {
      content: ' * ';
    }
  }

  i {
    color: $primary;
    margin-left: .75rem;
  }
}

.ng-select {
  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.custom-calendar {
  .btn {
    min-width: auto;
    margin: 0;
  }

  .custom-select {
    padding: 0 .5rem;
  }

  ngb-datepicker {
    padding: 0.75rem;
  }

  .ngb-dp {
    &-weekday,
    &-week-number {
      font-style: normal;
    }

    &-weekday {
      color: $nobel;
    }

    &-weekdays {
      background-color: white;
      padding: .5rem 0;
      margin-bottom: .5rem;
    }

    &-day,
    &-weekday,
    &-week-number {
      width: 2rem;
      height: 2rem;
      margin: 2px;
      font-weight: $font-weight-bold;
    }

    &-arrow {
      flex-grow: 0;
      order: 2;
      width: auto;
    }

    &-navigation-select {
      margin-left: 1rem;
    }

    &-today {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: .25rem;
        left: 50%;
        transform: translateX(-50%);
        height: .25rem;
        width: .25rem;
        border-radius: 50%;
        background-color: $primary;
      }
    }

    &-header {
      background-color: transparent;
    }
  }

  .ngb-dp-navigation-chevron {
    transform: rotate(45deg);
    color: $nobel;
    border-width: .125rem .125rem 0 0;
    width: .5rem;
    height: .5rem;
  }

  .right .ngb-dp-navigation-chevron {
    transform: rotate(-135deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.progress {
  padding: 2px;
  border: 1px solid rgba(151, 151, 151, 0.4);

  &-bar {
    border-radius: $progress-border-radius;

    &-striped {
      @include gradient-striped(white);
    }
  }
}

.request-preview {
  .nav-tabs {
    width: max-content;
    font-size: 12px;
    font-weight: 600;
  }
}

.fields-title {
  font-size: 18px;
  font-weight: $font-weight-bold;
  color: $cloudy;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.field {
  &__label {
    color: $tertiary;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: $font-weight-bold;
  }

  &__data {
    font-size: 16px;
    font-weight: $font-weight-bold;
    margin-bottom: 2rem;
  }

  i {
    color: $primary;
    margin-left: .5rem;
    float: right;
  }
}

.nav-tabs {
  .nav-link {
    color: $cloudy;
    padding: 1rem 2rem;

    &.active {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: -1px;
        height: 2px;
        width: 30%;
        left: 50%;
        transform: translateX(-50%);
        background-color: $primary;
      }
    }
  }

  .nav-item {
    + .nav-item {
      position: relative;

      &::after {
        content: '|';
        font-weight: $font-weight-light;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: $cloudy;
      }
    }
  }
}

.search-box {
  text-align: center;
  margin: 100px 0;
}

.icon-tag {
  height: 1rem;
  width: 1.5rem;
  background-color: $tertiary;
  color: $tertiary;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::after {
    content: "";
    border: .5rem solid transparent;
    border-right-color: inherit;
    width: 0;
    height: 0;
    position: absolute;
    left: -1rem;
    top: 0;
  }

  i {
    color: white;
    font-size: 10px;
  }
}

select{
  appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23%41%34%39%46%39%44' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left 0.8rem center;
    background-size: 1em;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: .5;
}

.abp-toast-close-button  {
  left: 0 !important;
  right: unset !important;
}